import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  const skills = [
    { name: "Python", level: "Advanced" },
    { name: "C#", level: "Intermediate" },
    { name: "Java", level: "Intermediate" },
    { name: "C++", level: "Beginner" },
    { name: "JavaScript", level: "Intermediate" },
    { name: "HTML/CSS", level: "Intermediate" },
    { name: "React", level: "Beginner" },
    { name: "Assembly", level: "Beginner" },
    { name: "Raspberry Pi Boards", level: "Intermediate" },
    { name: "Linux", level: "Intermediate" }
  ];

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>My Skills</h2>
              <p>Technologies and Programming Languages I Know:</p>
              
              <div className="skills-grid">
                {skills.map((skill, index) => (
                  <div key={index} className="skill-item">
                    <h5>{skill.name}</h5>
                    <div className="skill-level">{skill.level}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Arkaplan" />
    </section>
  )
}

// CSS Styles (Aynı dosyaya ekleyin veya ayrı CSS dosyasına koyun)
const styles = `
.skill {
  position: relative;
  padding: 50px 0 30px !important; /* Alt padding azalttık */
  overflow: hidden; /* Arkaplan resmi taşmalarını engeller */
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 40px 20px !important; /* Padding'leri azalttık */
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  max-width: 1000px;
  margin: 20px auto 0;
  padding: 0 15px;
}

.background-image-left {
  position: absolute;
  bottom: -50px; /* Arkaplan pozisyonunu ayarladık */
  left: 0;
  z-index: 1;
  opacity: 0.3;
  max-width: 400px;
}

/* Diğer stiller aynı kalabilir */
.skill-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 15px;
  transition: all 0.3s ease;
  cursor: default;
}

.skill-item:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.skill-item h5 {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.skill-level {
  color: #4dff9e;
  font-size: 0.9rem;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .skill {
    padding: 30px 0 20px !important;
  }
  
  .skill-bx {
    padding: 30px 15px !important;
  }
  
  .skills-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 10px;
  }
  
  .background-image-left {
    bottom: -30px;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .skills-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
`;

// CSS'i document head'e ekleme
if (typeof document !== 'undefined') {
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = styles;
  document.head.appendChild(styleSheet);
}