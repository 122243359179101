import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import e_okul from "../assets/img/görüntüle.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import YOK from "../assets/img/resim-yok.png";
import e_alarm from "../assets/img/e_alarm.png";
import alarm from "../assets/img/alarm.png";
import guvenlik from "../assets/img/ssler.jpg";
import aternos from "../assets/img/aternos.jpeg";
import mp4_photo from "../assets/img/mp4.jpg";
import messaging_fln from "../assets/img/messaging.png";

import uzayli_gif from "../assets/img/saat2.gif";
import track_png from "../assets/img/track.png";

import kayan_yazı from "../assets/img/kayan_yazi.gif";
import colorSharp2 from "../assets/img/color-sharp2.png";

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Student Management Software",
      description: "This desktop application was developed using C# Windows Forms. It includes features like creating classes, registering students to classes, and viewing class details.",
      imgUrl: e_okul,
      url: "https://github.com/deno832/programlar/tree/main/student_management"
    },
    {
      title: "Fake Aternos",
      description: "This website works like Aternos, allowing you to control your Minecraft server. It includes features like starting and stopping the server, sending commands, and editing the server.properties file. No front-end framework was used.",
      imgUrl: aternos,
      url: "https://github.com/deno832/programlar/tree/main/minecraft_server_site"
    },
    {
      title: "Scrolling Text with Assembly",
      description: "In this project, I programmed the Atmega 328p on an Arduino Uno using Assembly language to create scrolling text on a 16x2 LCD screen.",
      imgUrl: kayan_yazı,
      url: "https://github.com/deno832/programlar/tree/main/lcd_kayan_yaz%C4%B1_assembly"
    },
    {
      title: "Electro Alarm",
      description: "An alarm clock that activates an electric shock device 10 seconds after the alarm sounds if you don’t get up. Created using Arduino and an Arc Generator module.",
      imgUrl: e_alarm,
      url: "https://github.com/deno832/programlar/tree/main/elektroalarm_v2"
    },
    {
      title: "Face Recognition Security System",
      description: "A system with a camera placed on a room's door to recognize and store the faces of people entering the room in a database, viewable via a mobile app. Built using Raspberry Pi, Python, and Java.",
      imgUrl: guvenlik,
      url: "https://github.com/deno832/programlar/tree/main/y%C3%BCz_tan%C4%B1mal%C4%B1_g%C3%BCvenlik"
    },
    {
      title: "Alarm System",
      description: "A motion sensor placed in a room triggers a loud alarm sound on your phone when motion is detected. Created using Raspberry Pi, a motion sensor, Python, and Java.",
      imgUrl: alarm,
      url: ""
    },
    {
      title: "YouTube MP4 Downloader",
      description: "A YouTube MP4 downloader website developed with React and FastAPI. Initially hosted on my Raspberry Pi 4 using Cloudflare tunnels, but removed due to hardware limitations.",
      imgUrl: mp4_photo,
      url: "https://github.com/deno832/Youtube_mp4_Downloader/tree/main"
    },
    {
      title: "ASP.NET Messaging API",
      description: "An API for user registration, login, and message exchange, built using ASP.NET, Entity Framework ORM, and MSSQL. It utilizes SQL relationships.",
      imgUrl: messaging_fln,
      url: "https://github.com/deno832/programlar/tree/main/Mesajla%C5%9FmaApiAmaAsp.net/Mesajla%C5%9Fma_Api1"
    },
    {
      title: "WearOS Ben10",
      description: "A Ben10 watch app for WearOS, developed in Kotlin. There were no free Ben10 watch apps on the Play Store, so I created one. Planning to release it for free soon.",
      imgUrl: uzayli_gif,
      url: "https://github.com/deno832/WearOS_Ben10/tree/main"
    },
    {
      title: "Active Tracking Simulation Software",
      description: "This software, developed using Unity, Python, and OpenCV, is a PID active tracking simulation created for the Teknofest Fighting UAV competition.",
      imgUrl: track_png,
      url: "https://streamable.com/uyxb6q"
    }
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>My Projects</h2>
                <p>These are some of the projects I have developed so far. All of them were built independently by me. For more, you can visit my GitHub profile.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
